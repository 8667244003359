import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout';

export default function ServicesPage() {
  return (
    <>
      <Helmet>
        <title>Services | Two Thumbs Up</title>
      </Helmet>
      <Layout isHome={false}>
        <article className="prose">
          <h1>Two Thumbs Up Services</h1>
          <div className="grid grid-cols-1 gap-4 -mt-8 sm:grid-cols-2">
            <div>
              <p>Additions</p>
              <p>Bathrooms</p>
              <p>Kitchens</p>
              <p>Custom Cabinets</p>
            </div>
            <div className="-mt-10 sm:mt-0">
              <p>Outdoor Living</p>
              <p>Specialty Rooms</p>
              <p>Whole House Renovations</p>
            </div>
          </div>
        </article>
      </Layout>
    </>
  );
}
